import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-53319a43"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "joystick-keyboard-component"
};
var _hoisted_2 = {
  "class": "group"
};
var _hoisted_3 = {
  "class": "group"
};
var _hoisted_4 = {
  "class": "group"
};
var _hoisted_5 = {
  "class": "group",
  style: {
    "margin-left": ".2rem",
    "margin-right": "0"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode($setup["DeepSvgIcon"], {
    "class": "item",
    name: "svg-turn-left",
    style: {
      "margin-bottom": ".2rem"
    }
  }), _createElementVNode("div", {
    "class": _normalizeClass(["item2", {
      'item2-selected': $setup.keySave.includes($setup.KeyCode.KEY_A)
    }]),
    onMousedown: _cache[0] || (_cache[0] = function ($event) {
      return $setup.handleEventsDown($setup.KeyCode.KEY_A, 'a');
    }),
    onMouseup: _cache[1] || (_cache[1] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.KEY_A, 'a');
    }),
    onMouseout: _cache[2] || (_cache[2] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.KEY_A, 'a');
    })
  }, "A ", 34 /* CLASS, NEED_HYDRATION */), _createVNode($setup["DeepSvgIcon"], {
    "class": _normalizeClass(["item2", {
      'item2-selected': $setup.keySave.includes($setup.KeyCode.ARROW_LEFT)
    }]),
    name: "svg-triangle-left",
    style: {
      "padding": ".3rem"
    },
    onMousedown: _cache[3] || (_cache[3] = function ($event) {
      return $setup.handleEventsDown($setup.KeyCode.ARROW_LEFT, 'ArrowLeft');
    }),
    onMouseup: _cache[4] || (_cache[4] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.ARROW_LEFT, 'ArrowLeft');
    }),
    onMouseout: _cache[5] || (_cache[5] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.ARROW_LEFT, 'ArrowLeft');
    })
  }, null, 8 /* PROPS */, ["class"]), _createVNode($setup["DeepSvgIcon"], {
    "class": "item",
    name: "svg-arrow-left"
  })]), _createElementVNode("div", _hoisted_3, [_createVNode($setup["DeepSvgIcon"], {
    "class": "item",
    name: "svg-arrow-left",
    style: {
      "rotate": "90deg",
      "margin-bottom": ".2rem"
    }
  }), _createVNode($setup["DeepSvgIcon"], {
    "class": _normalizeClass(["item2", {
      'item2-selected': $setup.keySave.includes($setup.KeyCode.ARROW_UP)
    }]),
    name: "svg-triangle-top",
    style: {
      "padding": ".3rem"
    },
    onMousedown: _cache[6] || (_cache[6] = function ($event) {
      return $setup.handleEventsDown($setup.KeyCode.ARROW_UP, 'ArrowUp');
    }),
    onMouseup: _cache[7] || (_cache[7] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.ARROW_UP, 'ArrowUp');
    }),
    onMouseout: _cache[8] || (_cache[8] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.ARROW_UP, 'ArrowUp');
    })
  }, null, 8 /* PROPS */, ["class"]), _createVNode($setup["DeepSvgIcon"], {
    "class": _normalizeClass(["item2", {
      'item2-selected': $setup.keySave.includes($setup.KeyCode.ARROW_DOWN)
    }]),
    name: "svg-triangle-down",
    style: {
      "padding": ".3rem"
    },
    onMousedown: _cache[9] || (_cache[9] = function ($event) {
      return $setup.handleEventsDown($setup.KeyCode.ARROW_DOWN, 'ArrowDown');
    }),
    onMouseup: _cache[10] || (_cache[10] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.ARROW_DOWN, 'ArrowDown');
    }),
    onMouseout: _cache[11] || (_cache[11] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.ARROW_DOWN, 'ArrowDown');
    })
  }, null, 8 /* PROPS */, ["class"]), _createVNode($setup["DeepSvgIcon"], {
    "class": "item",
    name: "svg-arrow-left",
    style: {
      "rotate": "-90deg"
    }
  })]), _createElementVNode("div", _hoisted_4, [_createVNode($setup["DeepSvgIcon"], {
    "class": "item",
    name: "svg-turn-right",
    style: {
      "margin-bottom": ".2rem"
    }
  }), _createElementVNode("div", {
    "class": _normalizeClass(["item2", {
      'item2-selected': $setup.keySave.includes($setup.KeyCode.KEY_D)
    }]),
    onMousedown: _cache[12] || (_cache[12] = function ($event) {
      return $setup.handleEventsDown($setup.KeyCode.KEY_D, 'd');
    }),
    onMouseup: _cache[13] || (_cache[13] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.KEY_D, 'd');
    }),
    onMouseout: _cache[14] || (_cache[14] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.KEY_D, 'd');
    })
  }, "D ", 34 /* CLASS, NEED_HYDRATION */), _createVNode($setup["DeepSvgIcon"], {
    "class": _normalizeClass(["item2", {
      'item2-selected': $setup.keySave.includes($setup.KeyCode.ARROW_RIGHT)
    }]),
    name: "svg-triangle-right",
    style: {
      "padding": ".3rem"
    },
    onMousedown: _cache[15] || (_cache[15] = function ($event) {
      return $setup.handleEventsDown($setup.KeyCode.ARROW_RIGHT, 'ArrowRight');
    }),
    onMouseup: _cache[16] || (_cache[16] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.ARROW_RIGHT, 'ArrowRight');
    }),
    onMouseout: _cache[17] || (_cache[17] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.ARROW_RIGHT, 'ArrowRight');
    })
  }, null, 8 /* PROPS */, ["class"]), _createVNode($setup["DeepSvgIcon"], {
    "class": "item",
    name: "svg-arrow-left",
    style: {
      "rotate": "180deg"
    }
  })]), _createElementVNode("div", _hoisted_5, [_createVNode($setup["DeepSvgIcon"], {
    "class": "item",
    style: {
      "margin-bottom": ".2rem"
    },
    name: "chevrons-up"
  }), _createElementVNode("div", {
    "class": _normalizeClass(["item2", {
      'item2-selected': $setup.keySave.includes($setup.KeyCode.KEY_W)
    }]),
    onMousedown: _cache[18] || (_cache[18] = function ($event) {
      return $setup.handleEventsDown($setup.KeyCode.KEY_W, 'w');
    }),
    onMouseup: _cache[19] || (_cache[19] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.KEY_W, 'w');
    }),
    onMouseout: _cache[20] || (_cache[20] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.KEY_W, 'w');
    })
  }, "W ", 34 /* CLASS, NEED_HYDRATION */), _createElementVNode("div", {
    "class": _normalizeClass(["item2", {
      'item2-selected': $setup.keySave.includes($setup.KeyCode.KEY_S)
    }]),
    onMousedown: _cache[21] || (_cache[21] = function ($event) {
      return $setup.handleEventsDown($setup.KeyCode.KEY_S, 's');
    }),
    onMouseup: _cache[22] || (_cache[22] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.KEY_S, 's');
    }),
    onMouseout: _cache[23] || (_cache[23] = function ($event) {
      return $setup.handleEventsUp($setup.KeyCode.KEY_S, 's');
    })
  }, "S ", 34 /* CLASS, NEED_HYDRATION */), _createVNode($setup["DeepSvgIcon"], {
    "class": "item",
    name: "chevrons-down"
  })])]);
}