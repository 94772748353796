import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-81fc0cb6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "unknown-state-component"
};
var _hoisted_2 = {
  "class": "notice"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a;
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($setup.currentStepDesc || "\u98DE\u884C\u5668\u72B6\u6001\uFF1A[".concat((_a = $setup.dockMode) === null || _a === void 0 ? void 0 : _a.name, "]")), 1 /* TEXT */)]);
}