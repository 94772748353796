import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3a888ef1"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "unknown-state-component"
};
var _hoisted_2 = {
  "class": "notice"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a;
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, "机场状态：[" + _toDisplayString((_a = $setup.dockMode) === null || _a === void 0 ? void 0 : _a.name) + "]", 1 /* TEXT */)]);
}