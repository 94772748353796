import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-44b27189"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "param-edit-component"
};
var _hoisted_2 = {
  "class": "top"
};
var _hoisted_3 = {
  "class": "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString($props.title), 1 /* TEXT */), _createVNode($setup["DeepSvgIcon"], {
    name: "svg-notice",
    "class": "icon"
  })]), _createVNode($setup["DeepCounterVIew"], {
    "class": "bottom",
    unit: "m",
    max: $props.max,
    min: $props.min,
    modelValue: $setup.editValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.editValue = $event;
    })
  }, null, 8 /* PROPS */, ["max", "min", "modelValue"])]);
}