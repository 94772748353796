import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-99e00b62"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "idle-state-component"
};
var _hoisted_2 = {
  "class": "left"
};
var _hoisted_3 = {
  key: 0,
  "class": "joystick"
};
var _hoisted_4 = {
  key: 2,
  "class": "desc"
};
var _hoisted_5 = {
  key: 4,
  "class": "desc"
};
var _hoisted_6 = {
  key: 1,
  "class": "joystick-disable"
};
var _hoisted_7 = {
  key: 0,
  "class": "divider"
};
var _hoisted_8 = {
  key: 1,
  "class": "right"
};
var _hoisted_9 = {
  key: 2,
  "class": "right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$setup.drcStateConnected ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$setup.controlType === 0 ? (_openBlock(), _createBlock($setup["JoystickKeyboardComponent"], {
    key: 0
  })) : _createCommentVNode("v-if", true), $setup.controlType === 1 ? (_openBlock(), _createBlock($setup["DeepAspectView"], {
    key: 1,
    aspect: "1",
    style: {
      "width": "60%"
    }
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        name: "svg-joystick2",
        style: {
          "width": "100%",
          "height": "100%"
        }
      })];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $setup.controlType === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_4, "摇杆操作中")) : _createCommentVNode("v-if", true), $setup.controlType === 2 ? (_openBlock(), _createBlock($setup["DeepAspectView"], {
    key: 3,
    aspect: "1",
    style: {
      "width": "60%"
    }
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        name: "svg-joystick3",
        style: {
          "width": "100%",
          "height": "100%"
        }
      })];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $setup.controlType === 2 ? (_openBlock(), _createElementBlock("div", _hoisted_5, "轮盘控制中")) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_6, " 虚拟飞行状态未就绪 "))]), $setup.aircraftMode === 16 || $setup.aircraftMode === 3 ? (_openBlock(), _createElementBlock("div", _hoisted_7)) : _createCommentVNode("v-if", true), $setup.aircraftMode === 16 ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", {
    "class": "flight-setting",
    onClick: $setup.handleReturn
  }, "返航"), _createElementVNode("div", {
    "class": "flight-setting flight-setting-2",
    onClick: $setup.handleEmergencyStop
  }, "急停【SPACE】")])) : _createCommentVNode("v-if", true), $setup.aircraftMode === 3 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode($setup["ThemeDropdown"], {
    "class-default": false,
    placement: "top"
  }, {
    "default": _withCtx(function (_a) {
      var show = _a.show;
      return [_createElementVNode("div", {
        "class": _normalizeClass(["flight-setting", {
          'flight-setting-active': show
        }])
      }, "飞行设置", 2 /* CLASS */)];
    }),
    dropdown: _withCtx(function () {
      return [_createVNode($setup["ControlTunesSetting"], {
        style: {
          "margin-bottom": "15%",
          "margin-right": "1rem"
        },
        include: $setup.controlType === 0 ? ['joystick', 'flyTo'] : ['flyTo']
      }, null, 8 /* PROPS */, ["include"])];
    }),
    _: 1 /* STABLE */
  }), $setup.isWaylineType ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    "class": "flight-setting",
    onClick: $setup.handleRecoveryFlight
  }, "恢复航线")) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    "class": "flight-setting",
    onClick: $setup.handleReturn
  }, "返航")])) : _createCommentVNode("v-if", true)]);
}